import { Box, InputBase, Stack, Typography } from "@mui/material"
import AdminSidebar from "../components/adminSideBar"
import { Label } from "@mui/icons-material"
import Header from "../components/header"
import Deliverysettings from "./deliverysettings"

const Settings = ()=>{
    return(
        <Stack direction="row" sx={{ bgcolor: { sm: "grey.100", xs: "#fff" }, minHeight: "calc(100vh - 80px)" }}>
            <Header/>
            <Stack direction="row" sx={{ width:"100%",marginTop: "80px" }}>
                <AdminSidebar/>
                <Deliverysettings/>
            </Stack>
        </Stack>
    )
}

export default Settings