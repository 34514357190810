import { Box, Paper, Typography } from "@mui/material";
import { useEffect,useState } from 'react';
import { card_styles } from "../components/cards";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CustomAxios from '../utils/CustomAxios';

const CommisionHistoryComponent = () => {

    const [commisionData, setCommisionData] = useState(null);

    useEffect(() => {
        const payload = {
            order_id: null
        }
        CustomAxios.post('share-commision/', payload)
            .then((res) => {
                if (res.data) {
                    setCommisionData(res.data)
                }
            })
            .catch(err => console.error(err))
        
    }, [])

    return (
        <Box sx={{ padding: "32px", width: "100%", display: "flex", flexDirection: "column", gap: "32px" }}>
            <Paper sx={{ width: "calc(100% - 64px)", bgcolor: "#fff", padding: "32px", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <Typography sx={card_styles.header}>Commision History</Typography>
            </Paper>
            <Box>
                <TableContainer component={Paper} sx={{ bgcolor: "#fff" }}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead >
                            <TableRow>
                                <TableCell sx={{ fontWeight: "600", textAlign: "center" }}>Refered Username</TableCell>
                                <TableCell sx={{ fontWeight: "600", textAlign: "center" }}>Commision Received</TableCell>
                                <TableCell sx={{ fontWeight: "600", textAlign: "center" }} align="right">TDS Amount</TableCell>
                                <TableCell sx={{ fontWeight: "600", textAlign: "center" }} align="right">Order ID</TableCell>
                                <TableCell sx={{ fontWeight: "600", textAlign: "center" }} align="right">Purchased Order ID</TableCell>
                                <TableCell sx={{ fontWeight: "600", textAlign: "center" }} align="right">Purchased Order Username</TableCell>
                                <TableCell sx={{ fontWeight: "600", textAlign: "center" }} align="right">Order Amount</TableCell>
                                {/* <TableCell sx={{ fontWeight: "600", textAlign: "center" }} align="right">Package</TableCell> */}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            { commisionData?.length >= 1 ?
                                commisionData.map((product,prod_id) => {
                                    return (
                                        <TableRow
                                        key={prod_id}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell sx={{ textAlign:"center" }}>{product.referred_user ? product.referred_user.username : 'No User Found'}</TableCell>
                                            <TableCell sx={{ textAlign:"center" }}>{product.commission_received}</TableCell>
                                            <TableCell sx={{ textAlign:"center" }}>{product.tds_amount}</TableCell>
                                            <TableCell sx={{ textAlign:"center" }}>{product.orders.id}</TableCell>
                                            <TableCell sx={{ textAlign:"center" }}>{product.orders.razorpay_order_id || product.orders.cash_pickup_id || product.orders.order_id} </TableCell>
                                            <TableCell sx={{ textAlign:"center" }}>{product.orders.user ? product.orders.user.username : "No User Found"}</TableCell>
                                            <TableCell sx={{ textAlign:"center" }}>{product.orders.amount}</TableCell>
                                            {/* <TableCell sx={{ textAlign: "center" }}>{product.package.name}</TableCell> */}
                                        </TableRow>
                                    )
                                })
                                : null
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </Box>
    )
}

export default CommisionHistoryComponent