import { Box, Button, Grid, InputBase, Paper, Stack, Typography } from "@mui/material"
import { useEffect, useState } from "react"

import { InputNumber } from 'primereact/inputnumber';
import CustomAxios from "../utils/CustomAxios";
import { updateSnackBarMessage, updateSnackBarOpen, updateSnackBarSeverity } from "../redux/snackbar";
import { useDispatch } from "react-redux";
import { json } from "react-router-dom";
import MessageBar from "./messageBar";
        


export const formStyle = {
    inputBase: { border: "1px solid", borderColor: "grey.400", width: "100%", borderRadius: "5px", height: "48px", fontSize: "16px", padding: "14px", color: "grey.700" },
    label: { marginBottom: "12px", fontWeight: 600, fontSize: "14px" },
    textBox: { border: "1px solid", borderColor: "grey.400", width: "100%", borderRadius: "5px", fontSize: "16px", color: "grey.700" },
    button: { height: "3rem",padding:"0rem 1.25rem", bgcolor:"primary.light","&:hover":{bgcolor:"primary.light",color:"primary.contrastText"},textTransform: "none", fontWeight: 600, fontSize: "14px", maxWidth: "150px", alignSelf: "flex-end",borderRadius:"5px" },
    header: { fontSize: "20px" },
    subheader: { fontSize: "16px", color: "grey.700" }
}



const DeliverySettings = ()=>{
    const dispatch = useDispatch()
    const [waiveLimit,setWaiveLimit] = useState(null)
    const [errors, setErrors] = useState({})

    useEffect(()=>{
        CustomAxios.get('get-delivery-settings/1/').then((res)=>{
            let res_data = res.data
            if(res_data.deliverySettings_id){
                setWaiveLimit(res_data.waiver_amt)
            }
        }).catch((err)=>{
            console.error(err)
        })
    },[])

    const validate = ()=>{
        let error = {}
        let valid = true
        if(isNaN(waiveLimit) || !waiveLimit){
            error['waivelimit'] = "Enter a valid limit."
            valid = false
        }
        setErrors(error)
        return valid
    }

    const handleSave = ()=>{
        if(validate()){
            CustomAxios.put('update-delivery-settings/1/',{
                "waiver_amt": parseInt(waiveLimit)
            }).then((res)=>{
                let res_data = res.data
                if(res_data.deliverySettings_id){
                    setWaiveLimit(res_data.waiver_amt)
                    dispatch(updateSnackBarMessage(" Successfully updated settings "))
                    dispatch(updateSnackBarSeverity('success'))
                }
                else if(res_data.error){
                    dispatch(updateSnackBarMessage(res_data.error))
                    dispatch(updateSnackBarSeverity('error'))
                }
            }).catch((err)=>{
                console.error(err)
                dispatch(updateSnackBarMessage(JSON.stringify(err)))
                dispatch(updateSnackBarSeverity('error'))
            })
            dispatch(updateSnackBarOpen(true))
        }
    }
    return(
        <Stack sx={{p:"48px 32px",width:"inherit",gap:"32px"}}>
            <MessageBar/>
            <Box sx={{ borderBottom: "1px dashed grey ", paddingBottom: "45px" }}>
                <Typography sx={formStyle.header}>Settings</Typography>
            </Box>
            <Grid container>
                <Grid item container sx={{ borderBottom: "1px dashed grey ", paddingBottom: "45px", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <Grid item md={4}>
                        <Typography sx={formStyle.subheader}>Delivery Settings</Typography>
                    </Grid>
                    <Grid item md={8}>
                        <Paper sx={{ padding: "32px", display: "flex", flexDirection: "column", gap: "36px"}}>
                            <Stack>
                                <Typography sx={formStyle.label}>Waive limit</Typography>
                                <InputNumber value={waiveLimit} useGrouping={false} className="waive-limit-input" style={formStyle.inputBase} inputStyle={{border:"none",outline:"none"}} onChange={(e)=>setWaiveLimit(e.value)}/>
                                {/* <InputBase type="number" value={waiveLimit} sx={formStyle.inputBase} onChange={(e)=> setWaiveLimit(e.target.value)}/> */}
                                {errors.waivelimit && <Typography sx={{ pt:"10px",color: "error.light" }}>{errors.waivelimit}</Typography>}
                            </Stack>
                        </Paper>
                    </Grid>
                </Grid>
            </Grid>
            <Stack sx={{width:"inherit",justifyContent:"flex-end"}}>
                <Button sx={formStyle.button} variant="contained" onClick={(e)=>handleSave(e)}>Save</Button>
            </Stack>
        </Stack>
    )
}

export default DeliverySettings