import { Box, Button, Typography } from "@mui/material"
import { Link , useNavigate } from "react-router-dom"

export function Redirection({ headings, navigation, content, button,handleClose,scrollToSection }) {
    // const navigate = useNavigate()

    return (
        <Box sx={{ bgcolor: "white", padding: "24px", display: "flex", flexDirection: "column", alignItems: "flex-start", gap: "16px", maxWidth: "613px", width: "100%" }}>
            <Typography sx={{ fontWeight: "600", fontSize: "16px", lineHeight: "24px", letterSpacing: "0.15px", color: "primary.light" }}>{headings}</Typography>
            <Typography sx={{ fontWeight: "400", fontSize: "14px", lineHeight: "20px", letterSpacing: "0.25px", color: "black" }}>{content}</Typography>
            
            {navigation === 'mobileno'&&<Button variant="contained" onClick={() => {handleClose(); scrollToSection(navigation)}} sx={{ width: "100%", textTransform: "none" }}>{button}</Button>}
            {navigation !== 'mobileno'&&<Link to={navigation} style={{ width: "100%", textTransform: "none",bgcolor:"black",height:"36px",color:"white",textDecoration:"none",background:"#27A2FD",display:"flex",justifyContent:"center",alignItems:"center",fontFamily:"poppins",borderRadius:"4px" }}>{button}</Link>}
        </Box>

    )
}