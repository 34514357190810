import * as React from 'react';
import { useEffect,useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import axios from 'axios';
import CustomAxios from '../utils/CustomAxios';
import { useDispatch, useSelector } from 'react-redux';
import { updateAllProduct } from '../redux/products';
import { Alert, Box, Button, Snackbar } from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import { Link, useNavigate } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { updateSnackBarMessage, updateSnackBarOpen, updateSnackBarSeverity } from '../redux/snackbar';

export default function ProductList() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const products = useSelector(state => state.product.Allproducts)
  const [snackMsg,setSnackMsg] = useState("")
  const [snackOpen,setSnackOpen] = useState(false)
  const [snackSeverity,setSnackSeverity] = useState('error')

  
  useEffect(() => {
    // // console.log("------coming", products)
    getProducts()

  }, [])

  const getProducts = ()=>{
    CustomAxios.get('products/')
      .then((res) => {
        if (res.data) {
          dispatch(updateAllProduct(res.data))

        }
      })
      .catch(err => console.error(err))
  }

  const handleDeleteProduct = async(e,p_id)=>{
    e.preventDefault()
    let api_call = await CustomAxios.delete("products/"+p_id)
    if(api_call.status === 204){
        getProducts()
        dispatch(updateSnackBarMessage("Deleted"))
        dispatch(updateSnackBarSeverity("success"))
        // setSnackMsg("Deleted")
        // setSnackSeverity('success')
        // setSnackOpen(true)
    }
    else{
      dispatch(updateSnackBarMessage("Failed"))
      dispatch(updateSnackBarSeverity("error"))
      // setSnackMsg("Failed")
      // setSnackSeverity('error')
    }
    // setSnackOpen(true)
    dispatch(updateSnackBarOpen(true))

  }

  



  return (
    <Box>
      <TableContainer component={Paper} sx={{ bgcolor: "#fff" }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead >
            <TableRow>
              <TableCell sx={{ fontWeight: "600",textAlign:"center" }}>Product</TableCell>
              <TableCell sx={{ fontWeight: "600",textAlign:"center" }}>Product Type</TableCell>
              <TableCell sx={{ fontWeight: "600",textAlign:"center" }} align="right">Unit</TableCell>
              <TableCell sx={{ fontWeight: "600",textAlign:"center" }} align="right">Quantity</TableCell>
              <TableCell sx={{ fontWeight: "600",textAlign:"center" }} align="right">Price</TableCell>
              <TableCell sx={{ fontWeight: "600",textAlign:"center" }} align="right">Status</TableCell>
              <TableCell sx={{ fontWeight: "600",textAlign:"center" }} align="right">Actions</TableCell>

            </TableRow>
          </TableHead>
          <TableBody>
            {
              products?.length >= 1 ?
                products.map((product,prod_id) => {
                  return (

                    <TableRow
                      key={prod_id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell sx={{ fontWeight: "600",textAlign:"center" }}>{product.name}</TableCell>
                      <TableCell sx={{ fontWeight: "600",textAlign:"center" }}>{product.product_type}</TableCell>
                      <TableCell sx={{ fontWeight: "600",textAlign:"center" }}>{product.unit}</TableCell>
                      <TableCell sx={{ fontWeight: "600",textAlign:"center" }}>{product.available_quantity}</TableCell>
                      <TableCell sx={{ fontWeight: "600",textAlign:"center" }}>{product.price}</TableCell>
                      <TableCell sx={{ fontWeight: "600",textAlign:"center" }}>{product.status}</TableCell>
                      <TableCell sx={{ fontWeight: "600",display:"flex",alignItems:"center",justifyContent:"center",gap:"20px" }}>
                        <Edit sx={{color:"#6c6c6c",cursor:"pointer"}} onClick={()=>navigate(`${product.id}`)}/>
                        <Delete sx={{color:"red",cursor:"pointer"}} onClick={(e)=>handleDeleteProduct(e,product.id)}/>
                      </TableCell>
                    </TableRow>
                  )
                })
                : null
            }

          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
