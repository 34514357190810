import Footer from "../components/footer"
import { Box, Typography } from "@mui/material"
import { useNavigate } from "react-router-dom"
import { useEffect } from "react";
import Header1 from "../components/header1";

export const style = {
    title: { color: "grey.900", fontSize: "24px", fontWeight: "500", letterSpacing: "-1px", fontFamily: "'Poppins',sans-serif", lineHeight: "1.2em", marginBottom: "26px" },
    content: { color: "grey.700", fontSize: "14px", fontFamily: "'Poppins',sans-serif", lineHeight: "26px" }
}

const RefundPolicy = () => {
    useEffect(()=>{
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;

    },[])
    const navigate = useNavigate()
    return <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", width: "100%", overflow: "hidden" }}>
        <Header1 />
        <Box sx={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
            <Box sx={{ marginTop: {xs:"77px",md:"118px"}, height: "150px", bgcolor: "grey.200", width: "100%", display: "flex", justifyContent: "center" }}>
                <Box sx={{ padding: { xs: "16px", sm: "24px" }, display: "flex", alignItems: "center", flexDirection: { xs: "column", sm: "row" }, justifyContent: "space-between", maxWidth: "1440px", width: "100%" }}>
                    <Typography sx={{ fontSize: "30px", fontWeight: "500" }}>Refund and Returns Policy</Typography>
                    <Box sx={{ display: "flex", justifyContent: "space-between", gap: "10px" }}>
                        <Typography onClick={() => navigate("/")} sx={{ fontSize: "12px", fontWeight: "400", color: "#ea4d2a", cursor: "pointer" }}>Home</Typography>
                        <Typography sx={{ fontSize: "12px", fontWeight: "400" }}>{">"}</Typography>
                        <Typography sx={{ fontSize: "12px", fontWeight: "400", color: "grey.600" }}>Refund and Returns Policy</Typography>
                    </Box>

                </Box>

            </Box>
            <Box sx={{ maxWidth: "1440px", padding: { xs: "16px", sm: "24px" } }}>
                {/* <Divider sx={{ marginTop: "20px", marginBottom: "10px" }} /> */}
                <Box sx={{ width: "100%", display: "flex", justifyContent: "center", marginTop: "70px", flexDirection: "column", gap: "24px" }}>
                    <Box>

                        <Typography sx={style.title}>Overview</Typography>
                        <Typography sx={style.content}>Thank you for shopping with us at https://mayiiq.com. We strive to provide you with the best products and services, ensuring your satisfaction with every purchase. Please take a moment to review our return policy outlined below.</Typography>
                    </Box>
                    <Box>

                        <Typography sx={style.title}>Returns Eligibility</Typography>
                        <Typography sx={style.content}>We accept returns within 2 days of the delivery date for eligible products.</Typography>
                        <br />
                        <Typography sx={style.content}>To be eligible for a return, the item must be unused, unopened, and in the same condition as received. Unfortunately, we cannot accept returns for products that have been opened or used due to hygiene and safety reasons.</Typography>
                        <br />
                    </Box>
                    <Box>

                        <Typography sx={style.title}>Initiation of Returns</Typography>
                        <Typography sx={style.content}>To initiate a return, please contact our customer service team by creating a ticket at https://help.mayiiq.com OR by using our ChatBot in our website within 2 days of receiving your order.</Typography>
                        <br />
                        <Typography sx={style.content}>You can reach us via email at we need contact us email id or by phone at we need customer call toll free number.</Typography>
                        <br />
                        <Typography sx={style.content}>Please provide your order number and the reason for the return when contacting us.</Typography>
                        <br />
                    </Box>
                    <Box>

                        <Typography sx={style.title}>Return Process</Typography>
                        <Typography sx={style.content}>Once your return request is approved, we will provide you with instructions on how to return the item.</Typography>
                        <br />
                        <Typography sx={style.content}>Please ensure that the product is securely packaged to prevent damage during transit.</Typography>
                        <br />
                        <Typography sx={style.content}>We recommend using a trackable shipping service for returning your item.</Typography>
                        <br />
                        
                    </Box>
                    <Box>

                        <Typography sx={style.title}>Refund Policy</Typography>
                        <Typography sx={style.content}>Refunds will be processed upon receiving the returned item.</Typography>
                        <br />
                        <Typography sx={style.content}>The refund will be credited to the original payment method used for the purchase within 7-15 business working days.</Typography>
                        <br />
                        <Typography sx={style.content}>Please note that shipping fees are non-refundable, and return shipping costs are the responsibility of the customer unless the return is due to a mistake on our part.</Typography>
                        <br />
                    </Box>
                    <Box>

                        <Typography sx={style.title}>Exceptions</Typography>
                        <Typography sx={style.content}>We do not accept returns or provide refunds for opened or used products.</Typography>
                        <br />
                        <Typography sx={style.content}>Returns requested after the 2-day window from the delivery date will not be accepted.</Typography>
                        <br />
                    </Box>
                   
                    <Box>

                        <Typography sx={style.title}>Damaged or Defective Items</Typography>
                        <Typography sx={style.content}>If you receive a damaged or expired item, please contact us immediately for assistance..</Typography>
                        <br />
                        <Typography sx={style.content}>We will replace the item or issue a refund, depending on the availability of the product and your preference.</Typography>
                        <br />
                    </Box>
                    <Box>

                        <Typography sx={style.title}>Need help?</Typography>
                        <Typography sx={style.content}>Contact us at<a style={{color:"rgb(39,162,253,1)",cursor:"pointer",textDecoration:"none"}} href="mailto:refunds@mayiiq.com" > refunds@mayiiq.com </a>for questions related to refunds and returns OR use our state of the art chat feature to get your issues resolved.</Typography>
                        <br />
                        {/* <Typography sx={style.content}>We will replace the item or issue a refund, depending on the availability of the product and your preference.</Typography>
                        <br /> */}
                    </Box>
                </Box>
            </Box>
            <Footer />

        </Box>
    </Box>
}

export default RefundPolicy