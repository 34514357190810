
import { Box, Divider, Typography, Paper, InputBase, Button, RadioGroup, FormControlLabel, Radio, Grid, Chip, } from "@mui/material"
import { formStyle } from "../modules/profile";
import { Editor } from "primereact/editor";
// import { styles } from "./cards"
import { useEffect, useRef, useState } from "react";
import BackupIcon from '@mui/icons-material/Backup';
import { UseSelector, useDispatch, useSelector } from "react-redux";
import { updateProduct } from "../redux/products";
import axios from "axios";
import CustomAxios from '../utils/CustomAxios';
import { updateSnackBarMessage, updateSnackBarOpen, updateSnackBarSeverity } from "../redux/snackbar";
import MessageBar from "../modules/messageBar";
import { useNavigate } from "react-router-dom";
import { Close } from "@mui/icons-material";
import { jwtDecode } from "jwt-decode";
import CircularProgress from '@mui/material/CircularProgress';


export const Styles = {
    header: { fontSize: "20px" },
    subheader: { fontSize: "16px", color: "grey.700" }
}



const ProductForm = () => {
    const admin_details = jwtDecode(localStorage.getItem('authtoken'))
    // // console.log("-----------------------sdmin",admin_details)
    const product = useSelector(state => state.product?.product)
    const all_product = useSelector(state => state.product?.Allproducts)
    const [isloading, setIsloading] = useState(false)
    const [spefic_prod, setSpecific_prod] = useState('')
    const dispatch = useDispatch()
    const [errors, setErrors] = useState({})

    const fileRef = useRef()
    const GfileRef = useRef()
    const navigate = useNavigate()

    const handleProduct = async () => {
        const formData = new FormData()
        // // console.log(product.cover)
        if (product.cover) {
            formData.append('file', product.cover)
        }
        if (product.gallery?.length >= 1) {

            for (let i = 0; i < product.gallery.length; i++) {
                formData.append('files', product.gallery[i]);
            }
        }

        Object.entries({
            "name": product.name,
            "user_id": admin_details.id,
            "description": product.description,
            "available_quantity": parseInt(product.quantity),
            "price": parseInt(product.price),
            "selling_price": parseInt(product.sellingPrice),
            "tax": parseInt(product.tax),
            "product_type": "simple",
            "unit": product.unit,
            "slug": product.name?.split(" ").join("-"),
            "sku": product.sku,
            "status": product.status,
            "details":product.details,
            "length":parseFloat(product.length),
            "width":parseFloat(product.width),
            "height":parseFloat(product.height)

        }).forEach(([key, value]) => {
            formData.append(key, value);
        });

        // // console.log(product)
        const validate = validation(product)
        // // console.log(validate)

        if(validate){
            setIsloading(true)
            await CustomAxios.post(`products/`, formData).then((res) => {
                setIsloading(false)
                let data = res.data
                if (data.id) {
                    dispatch(updateProduct({}))
                    navigate('/product')
                    dispatch(updateSnackBarMessage("Product Created Sucessfully"))
                    dispatch(updateSnackBarSeverity("success"))
                }
                else if (data.error) {
                    // console.error(data.error)
                    if(data.error.search("Uploading Media")){
                        dispatch(updateSnackBarMessage(data.error))
                        dispatch(updateSnackBarSeverity("error"))
                    }
                    else{
                        dispatch(updateSnackBarMessage("Failed"))
                        dispatch(updateSnackBarSeverity("error"))
                    }
                }
            }).catch(err =>{
                // console.error(err)
                dispatch(updateSnackBarMessage("Something Went Wrong."))
            })
            setIsloading(false)
            dispatch(updateSnackBarOpen(true))
        }



    }
    const validation = (product) => {
        const newError = {}
        let valid = true

        // // console.log(product)
        if (!product.name?.trim()) {
            // // console.log("it s happening")
            newError.name = "Please enter product name here"
            valid = false

        }

        if (!product.unit?.trim()) {
            // // console.log("it s happening")
            newError.unit = "Please enter unit here"
            valid = false

        }
        if (!product.price) {
            // // console.log("it s happening")
            newError.price = "Please enter price here"
            valid = false

        }
        if(isNaN(parseFloat(product.price))){
            newError.sellingPrice = "Please enter valid price"
            valid = false
        }
        if (!product.sellingPrice) {
            // // console.log("it s happening")
            newError.sellingPrice = "Please enter selling price here"
            valid = false

        }
        if(isNaN(parseFloat(product.sellingPrice))){
            newError.sellingPrice = "Please enter valid selling price"
            valid = false
        }
        if(parseFloat(product.sellingPrice) > parseFloat(product.price)){
            newError.sellingPrice = "Selling price must be less than price"
            valid = false
        }

        
        if (!product.quantity) {
            // // console.log("it s happening")
            newError.quantity = "Please enter quantity here"
            valid = false
            
        }
        if(isNaN(parseFloat(product.quantity))){
            newError.tax = "Please enter valid quantity"
            valid = false
        }
        if (!product.sku?.trim()){
            // // console.log("it s happening")
            newError.sku="Please enter SKU here"
            valid = false
        }
        if (!product.description?.trim()){
            // // console.log("it s happening")
            newError.description="Please enter description here"
            valid = false
        }

        // }
        
        if (!product.tax?.trim()) {
            // // console.log("it s happening")
            newError.tax = "Please enter tax here"
            valid = false
            
        }
        if(isNaN(parseFloat(product.tax))){
            newError.tax = "Please enter valid tax"
            valid = false
        }
        if (!product.status?.trim()) {
            // // console.log("it s happening")
            newError.status = "Please enter status here"
            valid = false

        }

        if(!product.length || isNaN(parseFloat(product.length))){
            newError.length = "Please enter a valid length"
            valid = false
        }
        if(!product.width || isNaN(parseFloat(product.width))){
            newError.width = "Please enter a valid width"
            valid = false
        }
        if(!product.height || isNaN(parseFloat(product.height))){
            newError.height = "Please enter a valid height"
            valid = false
        }


        // // console.log(newError)
        setErrors(newError)

        return valid



    }

    const handleFileCheck = (file,files)=>{
        // // console.log("file check",file && file.type)
        if(file && file.type !== "" && file.type === 'image/png' || file.type === 'image/jpg' || file.type === 'image/jpeg'){
            // // console.log("------coming-------")
            dispatch(updateProduct({ ...product, cover: file }))
        }
        if(files?.length>=1){
            // // console.log(files)
            let values = files.filter((file)=> file.type !== 'image/png' && file.type !== 'image/jpg' && file.type !== 'image/jpeg')
            return values.length>=1?false:true
        }

    }

    const handleGalleryupdate = (e,f_id)=>{
        e.preventDefault()
        if(e.target.files){
            let files = Array.from(e.target.files)
            // // console.log("----------------")
            if(handleFileCheck(false,files)){
                dispatch(updateProduct({...product,gallery:files}))
            }
        }
        else{
            let dummy_details = [...product.gallery]
            dummy_details.splice(f_id,1)
            dispatch(updateProduct({...product,gallery:dummy_details}))
        }
    }

    return (<Box sx={{ padding: "48px 32px ", width: "100%", display: "flex", gap: "32px", flexDirection: "column", height: "calc(100vh - 180px)", overflowY: "scroll" }}>
        <MessageBar />
        <Box sx={{ borderBottom: "1px dashed grey ", paddingBottom: "45px" }}>


            <Typography sx={Styles.header}>
                Create New Product
            </Typography>
            {/* <Divider  sx={{marginTop:"32px",border:"1px dashed grey"}}/> */}
        </Box>
        <Box sx={{ borderBottom: "1px dashed grey ", paddingBottom: "45px", display: "flex", alignItems: "center", justifyContent: "space-between" }}>


            <Typography sx={[Styles.subheader, { alignSelf: "start" }]}>
                Feature Image
            </Typography>

            <Paper sx={{ padding: "32px", width: "60%" }}>
                <input ref={fileRef} type="file" accept=".png, .jpg, .jpeg" onChange={(e) => { handleFileCheck(e.target.files[0],false) }} style={{ display: "none" }} />
                <Box
                    onClick={() => { fileRef.current && fileRef.current.click() }}
                    // onDrop={handleDrop}
                    // onDragOver={handleDragOver}
                    sx={{ display: "flex", flexDirection: 'column', gap: "16px", border: "1px dashed grey", height: "150px", justifyContent: "center", alignItems: "center" }}>

                    <BackupIcon sx={{ color: "grey.500", fontSize: "32px", }} />
                    <Typography sx={{ color: "primary.dark" }} >Upload an Image</Typography>
                    <Typography sx={{ color: "grey.800" }} >PNG,JPG</Typography>

                </Box>
                <Grid container direction="row" sx={{ gap: "1rem", pt: "20px" }}>
                    {
                        product?.cover?.name && <Grid item >
                            <Chip label={product?.cover?.name} deleteIcon={<Close />} onDelete={() => dispatch(updateProduct({ ...product, cover: null }))} />
                        </Grid>
                    }
                </Grid>
            </Paper>
            {/* <Divider  sx={{marginTop:"32px",border:"1px dashed grey"}}/> */}
        </Box>
        <Box sx={{ borderBottom: "1px dashed grey ", paddingBottom: "45px", display: "flex", alignItems: "center", justifyContent: "space-between" }}>


            <Typography sx={[Styles.subheader, { alignSelf: "start" }]}>
                Gallery
            </Typography>
            <Paper sx={{ padding: "32px", width: "60%" }}>
                <input ref={GfileRef} multiple={true} accept=".png, .jpg, .jpeg" type="file" onChange={(e) => { handleGalleryupdate(e,null);}} style={{ display: "none" }} />
                <Box
                    onClick={() => { GfileRef.current && GfileRef.current.click() }}
                    // onDrop={handleDrop}
                    // onDragOver={handleDragOver}
                    sx={{ display: "flex", flexDirection: 'column', gap: "16px", border: "1px dashed grey", height: "150px", justifyContent: "center", alignItems: "center" }}>

                    <BackupIcon sx={{ color: "grey.500", fontSize: "32px", }} />
                    <Typography sx={{ color: "primary.dark" }} >Upload an Image</Typography>
                    <Typography sx={{ color: "grey.800" }} >PNG,JPG</Typography>

                </Box>
                <Grid container direction="row" sx={{ gap: "1rem", pt: "20px" }}>
                    {
                        product.gallery?.length>=1 && product.gallery.map((file, f_id) => {
                            return <Grid item key={f_id}>
                                {
                                    <Chip label={product.gallery[f_id].name} deleteIcon={<Close />} onDelete={(e) => handleGalleryupdate(e,f_id)} />
                                }
                            </Grid>
                        })
                    }
                </Grid>
            </Paper>
            {/* <Divider  sx={{marginTop:"32px",border:"1px dashed grey"}}/> */}
        </Box>
        <Box sx={{ borderBottom: "1px dashed grey ", paddingBottom: "45px", display: "flex", alignItems: "center", justifyContent: "space-between" }}>


            <Typography sx={[Styles.subheader, { alignSelf: "start" }]}>
                Description
            </Typography>
            <Paper sx={{ padding: "32px", display: "flex", flexDirection: "column", gap: "36px", width: "60%" }}>

                <Box>
                    <Typography sx={formStyle.label} color="grey.700" >Name <Typography component="span" color="error">*</Typography></Typography>
                    <InputBase sx={formStyle.inputBase} value={product.name} onChange={(e) => dispatch(updateProduct({ ...product, name: e.target.value }))} />
                    {errors.name && <Typography sx={{ color: "error.light" }}>{errors.name}</Typography>}
                </Box>
                <Box>
                    <Typography sx={formStyle.label} color="grey.700">Slug Name</Typography>
                    <InputBase sx={formStyle.inputBase} disabled value={product.slugname} onChange={(e) => dispatch(updateProduct({ ...product, slugname: e.target.value }))} />
                    {/* {errors.userNae && <Typography sx={{color:"error.light"}}>{errors.userName}</Typography>} */}
                </Box>
                <Box>
                    <Typography sx={formStyle.label} color="grey.700">Unit <Typography component="span" color="error">*</Typography></Typography>
                    <InputBase sx={formStyle.inputBase} value={product.unit} onChange={(e) => dispatch(updateProduct({ ...product, unit: e.target.value }))} />
                    {errors.unit && <Typography sx={{ color: "error.light" }}>{errors.unit}</Typography>}
                </Box>
                <Box>
                    <Typography sx={formStyle.label} color="grey.700">Brief <Typography component="span" color="error">*</Typography></Typography>

                    <InputBase multiline rows={5} sx={formStyle.textBox} value={product.details} onChange={(e) => dispatch(updateProduct({ ...product, details: e.target.value }))} />
                    {/* <Editor  style={formStyle.textBox}  value={product.description} onTextChange={(e) => dispatch(updateProduct({ ...product, description: e.htmlValue }))} /> */}

                    {errors.description && <Typography sx={{color:"error.light"}}>{errors.description}</Typography>}
                </Box>
                <Box>
                    <Typography sx={formStyle.label} color="grey.700">Description <Typography component="span" color="error">*</Typography></Typography>

                    {/* <InputBase multiline rows={5} sx={formStyle.textBox} value={product.description} onChange={(e) => dispatch(updateProduct({ ...product, description: e.target.value }))} /> */}
                    <Editor  style={formStyle.textBox}  value={product.description} onTextChange={(e) => dispatch(updateProduct({ ...product, description: e.htmlValue }))} />

                    {errors.description && <Typography sx={{color:"error.light"}}>{errors.description}</Typography>}
                </Box>
            </Paper>
            {/* <Divider  sx={{marginTop:"32px",border:"1px dashed grey"}}/> */}
        </Box>
        <Box sx={{ paddingBottom: "45px", display: "flex", alignItems: "center", justifyContent: "space-between" }}>


            <Typography sx={[Styles.subheader, { alignSelf: "start" }]}>
                Simple Product Information
            </Typography>
            <Paper sx={{ padding: "32px", display: "flex", flexDirection: "column", gap: "36px", width: "60%" }}>

                <Box>
                    <Typography sx={formStyle.label} color="grey.700">Price <Typography component="span" color="error">*</Typography></Typography>
                    <InputBase sx={formStyle.inputBase} value={product.price} onChange={(e) => dispatch(updateProduct({ ...product, price: e.target.value }))} />
                    {errors.price && <Typography sx={{ color: "error.light" }}>{errors.price}</Typography>}
                </Box>
                <Box>
                    <Typography sx={formStyle.label} color="grey.700">Sale Price <Typography component="span" color="error">*</Typography></Typography>
                    <InputBase sx={formStyle.inputBase} value={product.sellingPrice} onChange={(e) => dispatch(updateProduct({ ...product, sellingPrice: e.target.value }))} />
                    {errors.sellingPrice && <Typography sx={{ color: "error.light" }}>{errors.sellingPrice}</Typography>}
                </Box>
                <Box>
                    <Typography sx={formStyle.label} color="grey.700">Quantity <Typography component="span" color="error">*</Typography></Typography>
                    <InputBase sx={formStyle.inputBase} value={product.quantity} onChange={(e) => dispatch(updateProduct({ ...product, quantity: e.target.value }))} />
                    {errors.quantity && <Typography sx={{ color: "error.light" }}>{errors.quantity}</Typography>}
                </Box>
                <Box>
                    <Typography sx={formStyle.label} color="grey.700">SKU <Typography component="span" color="error">*</Typography></Typography>
                    <InputBase sx={formStyle.inputBase} value={product.sku} onChange={(e) => dispatch(updateProduct({ ...product, sku: e.target.value }))} />
                    {errors.sku && <Typography sx={{color:"error.light"}}>{errors.sku}</Typography>}
                </Box>
                <Box>
                    <Typography sx={formStyle.label} color="grey.700">Width <Typography component="span" color="error">*</Typography></Typography>
                    <InputBase required sx={formStyle.inputBase} value={product.width} onChange={(e) => dispatch(updateProduct({ ...product, width: e.target.value }))} />
                    {errors.width && <Typography sx={{color:"error.light"}}>{errors.width}</Typography>}
                </Box>
                <Box>
                    <Typography sx={formStyle.label} color="grey.700">Height <Typography component="span" color="error">*</Typography></Typography>
                    <InputBase required sx={formStyle.inputBase} value={product.height} onChange={(e) => dispatch(updateProduct({ ...product, height: e.target.value }))} />
                    {errors.height && <Typography sx={{color:"error.light"}}>{errors.height}</Typography>}
                </Box>
                <Box>
                    <Typography sx={formStyle.label} color="grey.700">Length <Typography component="span" color="error">*</Typography></Typography>
                    <InputBase required sx={formStyle.inputBase} value={product.length} onChange={(e) => dispatch(updateProduct({ ...product, length: e.target.value }))} />
                    {errors.length && <Typography sx={{color:"error.light"}}>{errors.length}</Typography>}
                </Box>
                <Box>
                    <Typography sx={formStyle.label} color="grey.700">Tax <Typography component="span" color="error">*</Typography></Typography>
                    <InputBase sx={formStyle.inputBase} value={product.tax} onChange={(e) => dispatch(updateProduct({ ...product, tax: e.target.value }))} />
                    {errors.tax && <Typography sx={{ color: "error.light" }}>{errors.tax}</Typography>}
                </Box>
                <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    sx={{ flexDirection: "row" }}
                    value={product.status}
                    onChange={(e) => dispatch(updateProduct({ ...product, status: e.target.value }))}
                >
                    <FormControlLabel value="draft" control={<Radio />} label="Draft" />
                    <FormControlLabel value="publish" control={<Radio />} label="Publish" />
                </RadioGroup>
                {errors.status && <Typography sx={{ color: "error.light" }}>{errors.status}</Typography>}
            </Paper>
            {/* <Divider  sx={{marginTop:"32px",border:"1px dashed grey"}}/> */}
        </Box>

        <Box sx={{ alignSelf: 'end' }}>
            <Button sx={formStyle.button} variant="contained" onClick={handleProduct}>{isloading? <CircularProgress color="inherit" size={30}/> : "Add Product"}</Button>
        </Box>

    </Box>)

}

export default ProductForm 