import { createSlice } from "@reduxjs/toolkit";


const initialState = {
    first_name:"",
    last_name:"",
    user_name:"",
    email:"",
    password:"",
    con_password:"",
    mobile_no:"",
    billing_address:[],
    shipping_address:[],
    bio:"",
    card:{},
    addressModal:false,
    id:"",
    addressList:[],
    address:{},
    role:"",
    allAddress:[],
    profile:'',
    otp:'',
    package:"",
    listitem:"",
    member_response:{},
    transactionPin:"",
    reconfirmPin:"",
    typeEnable:false,
    accumulated:{},
    transactionPinModal:false,
    selecteIdForTransaction:"",
    transactionpinVerification:"not verified",
    bankModal:false,
    bank:{},
    allBank:[],
    isProfileReloaded:false,
    overallSpend:""
};


const userDataReducer = createSlice({
    name: "userDataReducer",
    initialState: initialState,
    reducers: {
        updateFirstName: (state, { type, payload }) => {
            // console.log("user name",payload)
            state.first_name = payload
        },
        updateLastName: (state, { type, payload }) => {
            // console.log("user name",payload)
            state.last_name = payload
        },
        updateEmail: (state, { type, payload }) => {
            // console.log("user name",payload)
            state.email = payload
        },
        updateMobile: (state, { type, payload }) => {
            // // console.log("user name",payload)
            state.mobile_no = payload
        },
        updatePassword: (state, { type, payload }) => {
            // // console.log("user name",payload)
            state.password = payload
        },
        updateAddressModal: (state, { type, payload }) => {
            // // console.log("user name",payload)
            state.addressModal = payload
        },
        updateGetAddress:(state, {type, payload}) =>{
            state.address=payload
        },
        updateAddress: (state=initialState,  {type, payload, where} ) => {
            // console.log("user name",payload, where)
            //state.address = payload
            if (payload.add==="add"){
            return{...state, 
                    address:[...state.address, payload]}}
            else{
                state.address = payload
            }
        },
        updateId: (state, { type, payload }) => {
            // // console.log("user name",payload)
            state.id = payload
        },
        updateRole: (state, { type, payload }) => {
            // // console.log("user name",payload)
            state.role = payload
        },
        updateBio:(state, { type, payload }) => {
            // // console.log("user name",payload)
            state.bio = payload
        },
        updateAllAddress:(state, { type, payload }) => {
            // // console.log("user name",payload)
            state.allAddress = payload
        },
        updateProfile:(state, { type, payload }) => {
            state.profile = payload
            // console.log(payload)
        },
        updateOtpCheck:(state, { type, payload }) => {
            state.otp = payload
            // console.log(payload)
        },
        updatePackage:(state, { type, payload }) => {
            state.package = payload
            // console.log(payload)
        },
        updateListSelect:(state, { type, payload }) => {
            state.listitem = payload
            // console.log(payload)
        },
        updateMemberResponse:(state, { type, payload }) => {
            state.member_response = payload
            // console.log(payload)
        },
        updateUserData:(state,{type,payload}) =>{
            // console.log("------data---------",payload)
            state[payload.type] = payload.data
        },
        updateUserName:(state,{type,payload}) =>{
            // console.log("------data---------",payload.data)
            state.user_name = payload
        },
        updateTransactionPin:(state, { type, payload }) => {
            state.transactionPin = payload
        },
        updateReconfirmPin:(state, { type, payload }) => {
            state.reconfirmPin = payload
        },
        updateTypeEnable:(state, { type, payload }) => {
            state.typeEnable = payload
        },
        updateAccumulated:(state, { type, payload }) => {
            state.accumulated = payload
        },
        updateTransactionPinModal:(state, { type, payload }) => {
            console.log(payload)
            state.transactionPinModal = payload
        },
        updateSelecteIdForTransaction:(state, { type, payload }) => {
            console.log(payload)
            state.selecteIdForTransaction = payload
        },
        updateTransactionPinVerification:(state, { type, payload }) => {
            console.log(payload)
            state.transactionpinVerification = payload
        },
        updateBankModal: (state, { type, payload }) => {
            // // console.log("user name",payload)
            state.bankModal = payload
        },
        updateGetBank:(state, {type, payload}) =>{
            state.allBank=payload
        },
        updateBank: (state=initialState,  {type, payload, where} ) => {
            // console.log("user name",payload, where)
            state.bank = payload
        //     if (payload.add==="add"){
        //     return{...state, 
        //             bank:[...state.bank, payload]}}
        //     else{
        //         return{...state, 
        //             bank:{...state.bank,...payload}
        //     }
        // }
        },
        updateIsProfileReloaded:(state,{ type, payload })=>{
            state.isProfileReloaded = payload
        },
        updateOverallSpend:(state, { type, payload }) => {
            state.overallSpend = payload
        },
      
    }
    })

    export const { 
        updateFirstName ,updateUserName,updateLastName,updateEmail,updateMobile,updatePassword,updateAddressModal,updateAddress,updateBio,
        updateRole,updateId,updateAllAddress, updateProfile, updateOtpCheck, updatePackage, updateListSelect, updateMemberResponse,updateUserData,
        updateTransactionPin,updateReconfirmPin,updateTypeEnable,updateAccumulated,updateTransactionPinModal,updateSelecteIdForTransaction ,updateTransactionPinVerification,updateBankModal,updateGetBank,updateBank,
        updateIsProfileReloaded,updateOverallSpend
    } = userDataReducer.actions
    
    
    
    export default userDataReducer.reducer;