
import { Box, Divider, Typography, Paper, InputBase, Button, RadioGroup, FormControlLabel, Radio, Chip, Stack, Grid, } from "@mui/material"
import { formStyle } from "../modules/profile";
import { Editor } from "primereact/editor";
// import { styles } from "./cards"
import { useEffect, useRef, useState } from "react";
import BackupIcon from '@mui/icons-material/Backup';
import { UseSelector, useDispatch, useSelector } from "react-redux";
import { updateProduct } from "../redux/products";
import axios from "axios";
import CustomAxios from '../utils/CustomAxios';
import { json, useNavigate } from "react-router-dom";
import { Close } from "@mui/icons-material";
import { updateSnackBarMessage, updateSnackBarOpen, updateSnackBarSeverity } from "../redux/snackbar";
import CircularProgress from '@mui/material/CircularProgress';

export const Styles = {
    header: { fontSize: "20px" },
    subheader: { fontSize: "16px", color: "grey.700" }
}



const ProductUpdateForm = () => {
    const urlId = window.location.pathname.split('/')[2]
    // console.log("----------urlid--------",urlId)
    const product = useSelector(state => state.product?.product)
    const all_product = useSelector(state => state.product?.Allproducts)
    const [isloading, setIsloading] = useState(false)
    const [spefic_prod,setSpecific_prod] = useState('')
    const dispatch = useDispatch()
    const [errors, setErrors] = useState({})
    const [newlyUploadedFiles,setNewlyUploadedFiles] = useState([])
    const [newlyUploadedCoverFiles,setNewlyUploadedCoverFiles] = useState("")
    const fileRef = useRef()
    const GfileRef = useRef()
    const navigate = useNavigate()

    useEffect(()=>{
        if(isNaN(urlId) === false){
            let spefic_prod = all_product.filter(item=>item.id === parseInt(urlId))[0]
            setSpecific_prod(spefic_prod)
            dispatch(updateProduct({ ...spefic_prod,
                selling_price:parseInt(spefic_prod.selling_price),
                price:parseInt(spefic_prod.price),
                up_gallery:spefic_prod?.gallery_details?.original && JSON.parse(spefic_prod.gallery_details.original)
                //  cover:spefic_prod.cover_details?.original,
                //  gallery:spefic_prod.gallery_details?.original?JSON.parse(spefic_prod.gallery_details?.original):[],
                //  name:spefic_prod.name,
                //  description:spefic_prod.description,
                //  unit:spefic_prod.unit,
                //  tax:spefic_prod.tax,
                //  sku:spefic_prod.sku,
                //  status:spefic_prod.status,
                //  slugname:spefic_prod.slug,
                //  sellingPrice:spefic_prod.selling_price,
                //  quantity:spefic_prod.available_quantity,
                //  price:spefic_prod.price,
                //  type:product.status
                 
                }))
                // // console.log(spefic_prod?.cover_details?.original.split(`products-media/${spefic_prod.created_by}/${spefic_prod.name}/`))
                setNewlyUploadedCoverFiles(spefic_prod?.cover_details?.original?.split(`products-media/${spefic_prod.created_by}/${spefic_prod.slug}/cover/`)[1])
            // console.log("-----calling api call for update------------",product)
            // axios.get(process.env.REACT_APP_BACKEND_BASE_URL+"products/"+urlId)
        }
    },[urlId])


    const validateProduct = (product) => {
        const newError = {}
        let valid = true

        // // console.log(product)
        if (!product.name?.trim()) {
            // console.log("it s happening")
            newError.name = "Please enter product name here"
            valid = false

        }

        if (!product.unit?.trim()) {
            // console.log("it s happening")
            newError.unit = "Please enter unit here"
            valid = false

        }
        if (!product.price) {
            // console.log("it s happening")
            newError.price = "Please enter price here"
            valid = false

        }
        if (!product.selling_price) {
            // console.log("it s happening")
            newError.sellingPrice = "Please enter selling price here"
            valid = false

        }
        if(product.selling_price > product.price){
            newError.sellingPrice = "Selling price must be less than price"
            valid = false
        }
        if (!product.available_quantity) {
            // console.log("it s happening")
            newError.quantity = "Please enter quantity here"
            valid = false

        }
        if (!product.sku?.trim()){
            // console.log("it s happening")
            newError.sku="Please enter SKU here"
            valid = false
        }
        if (!product.description?.trim()){
            // console.log("it s happening")
            newError.description="Please enter description here"
            valid = false
        }

        // }
        if (!product.tax?.trim()) {
            // console.log("it s happening")
            newError.tax = "Please enter tax here"
            valid = false

        }
        if (!product.status?.trim()) {
            // console.log("it s happening")
            newError.status = "Please enter status here"
            valid = false

        }

        if(!product.length || isNaN(parseFloat(product.length))){
            newError.length = "Please enter a valid length"
            valid = false
        }
        if(!product.width || isNaN(parseFloat(product.width))){
            newError.width = "Please enter a valid width"
            valid = false
        }
        if(!product.height || isNaN(parseFloat(product.height))){
            newError.height = "Please enter a valid height"
            valid = false
        }




        // console.log(newError)
        setErrors(newError)

        return valid



    }



    const handleUpdateProduct = ()=>{
        // console.log(product,spefic_prod)
        let dummy_details = {...product}
        let product_keys = Object.keys(product)
        let spefic_prod_keys =Object.keys(spefic_prod).filter(e=>product_keys.includes(e)) 
        // console.log(product_keys,spefic_prod_keys)
        let api_data = new FormData()
        api_data.append('user_id',spefic_prod.created_by)
        product_keys.map((key)=>{
            if(spefic_prod[key]!=product[key]){
                if(key === "cover"){
                    api_data.append("file",product[key])
                }
                else if(key === "gallery"){
                    for(let i=0;i<product[key].length;i++){
                        api_data.append("files",product[key][i])
                    }
                }
                else if(key === "up_gallery" && spefic_prod.gallery_details.original){
                    let urls=[]
                    JSON.parse(spefic_prod.gallery_details.original).map((e)=>{
                        if(!product.up_gallery.includes(e)){
                            urls.push(e)
                        }
                    })
                    if(urls.length >=1){

                        api_data.append("delete_gallery_urls",JSON.stringify(urls))
                    }
                }
                else{
                    api_data.append(key,product[key])
                }
            }
        })
        api_data.forEach((val,key)=>{
            // console.log(key,"--->",val)
        })
        if(validateProduct(product)){
            setIsloading(true)
            CustomAxios.put('products/'+spefic_prod.id+"/",api_data).then((res)=>{
                setIsloading(false)
                let data = res.data
                if(data.id){
                    navigate('/product')
                    dispatch(updateProduct({}))
                    dispatch(updateSnackBarMessage("Updated Successfully"))
                    dispatch(updateSnackBarSeverity("success"))
                }
                else if(data.error){
                    // console.error(data.error)
                    dispatch(updateSnackBarMessage("Failed"))
                    dispatch(updateSnackBarSeverity("error"))
                }
            }).catch(err=>{
                setIsloading(false)
                // console.error(err)
                dispatch(updateSnackBarMessage("Failed"))
                dispatch(updateSnackBarSeverity("error"))
            })
            dispatch(updateSnackBarOpen(true))

        }
        else{
            // console.log("validation failed----------")
        }
    }

    const handleUpdateGallleryImgs = (url,index)=>{
        if(url){

            let dummy_gallery = [...product.up_gallery]
            // console.log(dummy_gallery)
            delete dummy_gallery[index]
            // console.log(dummy_gallery)
            dispatch(updateProduct({...product,up_gallery:dummy_gallery}))
        }
        else{
            let dummy_details = [...newlyUploadedFiles]
            delete dummy_details[index]
            setNewlyUploadedFiles(dummy_details)
        }
    }

    const handleFileCheck = (file,files)=>{
        // // console.log("file check",file)
        if(file && file.type !== "" && file.type === 'image/png' || file.type === 'image/jpg' || file.type === 'image/jpeg'){
            // // console.log("------coming-------")
            return true
        }
        if(files){
            // // console.log(files)
            let values = Array.from(files).filter((file)=> file.type !== 'image/png' && file.type !== 'image/jpg' && file.type !== 'image/jpeg')
            return values.length>=1?false:true
        }

    }


    const handleGalleryUpload = (e)=>{
        e.preventDefault()
        let files = e.target.files
        // console.log(files)
        let dummy_gallery = []
        if(files.length>=1 && handleFileCheck(false,files) ){
            dispatch(updateProduct({ ...product, gallery: e.target.files }))
            for (let i = 0; i < files.length; i++) {
                dummy_gallery.push(files[i].name)
            }
            setNewlyUploadedFiles(dummy_gallery)
        }
    }

    const handleCoverUpdate = (e)=>{
        e.preventDefault()
        let up_file = e.target.files[0]
        if(up_file && handleFileCheck(up_file,false)){
            // console.log("----------update up cover------",up_file.name)
            let dummy_details = {...product}
            dummy_details['up_cover'] = ""
            // console.log("----------------",dummy_details)
            dispatch(updateProduct({...dummy_details}))
            setNewlyUploadedCoverFiles(up_file.name)
            dispatch(updateProduct({ ...product, cover: e.target.files[0] }))
        }
    }
    
    const handleCoverDelete = (e,up_cover)=>{
        e.preventDefault()
        if(up_cover){
            let dummy_details = {...product}
            delete dummy_details['up_cover']
            // console.log("----------------")
            dispatch(updateProduct({...dummy_details}))
        }
        else{
            setNewlyUploadedCoverFiles("")
        }

    }


    return (<Box sx={{ padding: "48px 32px ", width: "100%", display: "flex", gap: "32px", flexDirection: "column", height: "calc(100vh - 180px)", overflowY: "scroll" }}>
        <Box sx={{ borderBottom: "1px dashed grey ", paddingBottom: "45px" }}>


            <Typography sx={Styles.header}>
                Update Product
            </Typography>
            {/* <Divider  sx={{marginTop:"32px",border:"1px dashed grey"}}/> */}
        </Box>
        <Box sx={{ borderBottom: "1px dashed grey ", paddingBottom: "45px", display: "flex", alignItems: "center", justifyContent: "space-between" }}>


            <Typography sx={[Styles.subheader, { alignSelf: "start" }]}>
                Feature Image
            </Typography>

            <Paper sx={{ padding: "32px", width: "60%" }}>
                <input ref={fileRef} type="file" accept=".png, .jpg, .jpeg" onChange={(e) => {handleCoverUpdate(e);  }} style={{ display: "none" }} />
                <Box
                    onClick={() => { fileRef.current && fileRef.current.click() }}
                    // onDrop={handleDrop}
                    // onDragOver={handleDragOver}
                    sx={{ display: "flex", flexDirection: 'column', gap: "16px", border: "1px dashed grey", height: "150px", justifyContent: "center", alignItems: "center" }}>

                    <BackupIcon sx={{ color: "grey.500", fontSize: "32px", }} />
                    <Typography sx={{ color: "primary.dark" }} >Upload an Image</Typography>
                    <Typography sx={{ color: "grey.800" }} >PNG,JPG</Typography>

                </Box>
                <Grid container sx={{gap:"1rem",pt:"20px"}}>
                    {/* {product.up_cover &&<Grid item>
                        <Chip label={product?.up_cover&&product?.up_cover?.split('products-media/')[1].split('/')[1]} deleteIcon={<Close/>} onDelete={(e)=>{handleCoverDelete(e,true)}}/>
                    </Grid> } */}
                    {newlyUploadedCoverFiles &&
                    <Grid item>

                        <Chip label={newlyUploadedCoverFiles} deleteIcon={<Close/>} onDelete={(e)=>{handleCoverDelete(e,false)}}/>
                    </Grid>
                    }
                </Grid>
            </Paper>
            {/* <Divider  sx={{marginTop:"32px",border:"1px dashed grey"}}/> */}
        </Box>
        <Box sx={{ borderBottom: "1px dashed grey ", paddingBottom: "45px", display: "flex", alignItems: "center", justifyContent: "space-between" }}>


            <Typography sx={[Styles.subheader, { alignSelf: "start" }]}>
                Gallery
            </Typography>
            <Paper sx={{ padding: "32px", width: "60%" }}>
                <input ref={GfileRef} multiple={true} type="file" onChange={(e) => { handleGalleryUpload(e) ; }} style={{ display: "none" }} />
                <Box
                    onClick={() => { GfileRef.current && GfileRef.current.click() }}
                    // onDrop={handleDrop}
                    // onDragOver={handleDragOver}
                    sx={{ display: "flex", flexDirection: 'column', gap: "16px", border: "1px dashed grey", height: "150px", justifyContent: "center", alignItems: "center" }}>

                    <BackupIcon sx={{ color: "grey.500", fontSize: "32px", }} />
                    <Typography sx={{ color: "primary.dark" }} >Upload an Image</Typography>
                    <Typography sx={{ color: "grey.800" }} >PNG,JPG</Typography>

                </Box>

                <Grid container direction="row" sx={{gap:"1rem",pt:"20px"}}>
                    
                    {
                        product?.up_gallery?.length>=1 && product.up_gallery.map((img,img_id)=>{
                            
                            return(
                                img && <Grid item key={img_id}>

                                    <Chip label={img&&img.split(`products-media/${spefic_prod.created_by+"/"+spefic_prod.slug+"/gallery/"}`)[1]} deleteIcon={<Close/>} onDelete={()=> handleUpdateGallleryImgs(true,img_id)}/>
                                </Grid>
                            )
                        })
                    }
                    {
                        newlyUploadedFiles.map((name,name_i)=>{
                            return(
                                name && <Grid item key={name_i}>
                                    <Chip label={name} deleteIcon={<Close/>} onDelete={()=> handleUpdateGallleryImgs(false,name_i)}/>
                                </Grid>
                            )
                        })
                    }
                </Grid>

            </Paper>
            {/* <Divider  sx={{marginTop:"32px",border:"1px dashed grey"}}/> */}
        </Box>
        <Box sx={{ borderBottom: "1px dashed grey ", paddingBottom: "45px", display: "flex", alignItems: "center", justifyContent: "space-between" }}>


            <Typography sx={[Styles.subheader, { alignSelf: "start" }]}>
                Description
            </Typography>
            <Paper sx={{ padding: "32px", display: "flex", flexDirection: "column", gap: "36px", width: "60%" }}>

                <Box>
                    <Typography sx={formStyle.label} color="grey.700" >Name</Typography>
                    <InputBase sx={formStyle.inputBase} value={product.name} onChange={(e) => dispatch(updateProduct({ ...product, name: e.target.value }))} />
                    {errors.name && <Typography sx={{ color: "error.light" }}>{errors.name}</Typography>}
                </Box>
                <Box>
                    <Typography sx={formStyle.label} color="grey.700">Slug Name</Typography>
                    <InputBase sx={formStyle.inputBase} disabled value={product.slug} onChange={(e) => dispatch(updateProduct({ ...product, slug: e.target.value }))} />
                    {/* {errors.userNae && <Typography sx={{color:"error.light"}}>{errors.userName}</Typography>} */}
                </Box>
                <Box>
                    <Typography sx={formStyle.label} color="grey.700">Unit</Typography>
                    <InputBase sx={formStyle.inputBase} value={product.unit} onChange={(e) => dispatch(updateProduct({ ...product, unit: e.target.value }))} />
                    {errors.unit && <Typography sx={{ color: "error.light" }}>{errors.unit}</Typography>}
                </Box>
                <Box>
                    <Typography sx={formStyle.label} color="grey.700">Brief <Typography component="span" color="error">*</Typography></Typography>

                    <InputBase multiline rows={5} sx={formStyle.textBox} value={product.details} onChange={(e) => dispatch(updateProduct({ ...product, details: e.target.value }))} />
                    {/* <Editor  style={formStyle.textBox}  value={product.description} onTextChange={(e) => dispatch(updateProduct({ ...product, description: e.htmlValue }))} /> */}

                    {errors.description && <Typography sx={{color:"error.light"}}>{errors.description}</Typography>}
                </Box>
                <Box>
                    <Typography sx={formStyle.label} color="grey.700">Description</Typography>

                    {/* <InputBase multiline rows={5} sx={formStyle.textBox} value={product.description} onChange={(e) => dispatch(updateProduct({ ...product, description: e.target.value }))} /> */}
                    <Editor  style={formStyle.textBox}  value={product.description} onTextChange={(e) => dispatch(updateProduct({ ...product, description: e.htmlValue }))} />

                    {/* {errors.userName && <Typography sx={{color:"error.light"}}>{errors.userName}</Typography>} */}
                </Box>
            </Paper>
            {/* <Divider  sx={{marginTop:"32px",border:"1px dashed grey"}}/> */}
        </Box>
        <Box sx={{ paddingBottom: "45px", display: "flex", alignItems: "center", justifyContent: "space-between" }}>


            <Typography sx={[Styles.subheader, { alignSelf: "start" }]}>
                Simple Product Information
            </Typography>
            <Paper sx={{ padding: "32px", display: "flex", flexDirection: "column", gap: "36px", width: "60%" }}>

                <Box>
                    <Typography sx={formStyle.label} color="grey.700">Price</Typography>
                    <InputBase sx={formStyle.inputBase} value={product.price} onChange={(e) => dispatch(updateProduct({ ...product, price: e.target.value }))} />
                    {errors.price && <Typography sx={{ color: "error.light" }}>{errors.price}</Typography>}
                </Box>
                <Box>
                    <Typography sx={formStyle.label} color="grey.700">Sale Price</Typography>
                    <InputBase sx={formStyle.inputBase} value={product.selling_price} onChange={(e) => dispatch(updateProduct({ ...product, selling_price: e.target.value }))} />
                    {errors.sellingPrice && <Typography sx={{ color: "error.light" }}>{errors.sellingPrice}</Typography>}
                </Box>
                <Box>
                    <Typography sx={formStyle.label} color="grey.700">Quantity</Typography>
                    <InputBase sx={formStyle.inputBase} value={product.available_quantity} onChange={(e) => dispatch(updateProduct({ ...product, available_quantity: e.target.value }))} />
                    {errors.quantity && <Typography sx={{ color: "error.light" }}>{errors.quantity}</Typography>}
                </Box>
                <Box>
                    <Typography sx={formStyle.label} color="grey.700">SKU</Typography>
                    <InputBase sx={formStyle.inputBase} value={product.sku} onChange={(e) => dispatch(updateProduct({ ...product, sku: e.target.value }))} />
                    {/* {errors.sku && <Typography sx={{color:"error.light"}}>{errors.userName}</Typography>} */}
                </Box>
                <Box>
                    <Typography sx={formStyle.label} color="grey.700">Width <Typography component="span" color="error">*</Typography></Typography>
                    <InputBase required sx={formStyle.inputBase} value={product.width} onChange={(e) => dispatch(updateProduct({ ...product, width: e.target.value }))} />
                    {errors.width && <Typography sx={{color:"error.light"}}>{errors.width}</Typography>}
                </Box>
                <Box>
                    <Typography sx={formStyle.label} color="grey.700">Height <Typography component="span" color="error">*</Typography></Typography>
                    <InputBase required sx={formStyle.inputBase} value={product.height} onChange={(e) => dispatch(updateProduct({ ...product, height: e.target.value }))} />
                    {errors.height && <Typography sx={{color:"error.light"}}>{errors.height}</Typography>}
                </Box>
                <Box>
                    <Typography sx={formStyle.label} color="grey.700">Length <Typography component="span" color="error">*</Typography></Typography>
                    <InputBase required sx={formStyle.inputBase} value={product.length} onChange={(e) => dispatch(updateProduct({ ...product, length: e.target.value }))} />
                    {errors.length && <Typography sx={{color:"error.light"}}>{errors.length}</Typography>}
                </Box>
                <Box>
                    <Typography sx={formStyle.label} color="grey.700">Tax</Typography>
                    <InputBase sx={formStyle.inputBase} value={product.tax} onChange={(e) => dispatch(updateProduct({ ...product, tax: e.target.value }))} />
                    {errors.tax && <Typography sx={{ color: "error.light" }}>{errors.tax}</Typography>}
                </Box>
                <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    sx={{ flexDirection: "row" }}
                    value={product.status?product.status:'draft'}
                    onChange={(e) => dispatch(updateProduct({ ...product, status: e.target.value }))}
                >
                    <FormControlLabel value="draft" control={<Radio />} label="Draft" />
                    <FormControlLabel value="publish" control={<Radio />} label="Publish" />
                </RadioGroup>
                {errors.status && <Typography sx={{ color: "error.light" }}>{errors.status}</Typography>}
            </Paper>
            {/* <Divider  sx={{marginTop:"32px",border:"1px dashed grey"}}/> */}
        </Box>

        <Box sx={{ alignSelf: 'end' }}>
            <Button sx={formStyle.button} variant="contained" onClick={handleUpdateProduct}>Update</Button>
        </Box>

    </Box>)

}

export default ProductUpdateForm 