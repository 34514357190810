import { Box,Paper,Button,Typography } from "@mui/material"
import { card_styles } from "../components/cards"
import { Link } from "react-router-dom"
import ProductList from "./productList"
import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { updateProduct } from "../redux/products"
const Products=()=>{
    const dispatch = useDispatch()
    useEffect(()=>{
        dispatch(updateProduct({}))
    },[])

return (
    <Box sx={{ padding: "32px", width: "100%",display:"flex",flexDirection:"column", gap:"32px" }} >

                <Paper sx={{ width: "calc(100% - 64px)", bgcolor: "#fff", padding: "32px", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <Typography sx={card_styles.header}>Products</Typography>
                    <Button style={card_styles.Button} variant="contained" component={Link} to="/product/new" >+ Add Products</Button>
                </Paper>
                <ProductList/>

            </Box>
)
}

export default Products